import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/pages/HomePage.vue';
import LoginPage from '@/pages/LoginPage.vue';
import ForgotPasswordPage from "@/pages/ForgotPasswordPage.vue";
import ResetPasswordPage from "@/pages/ResetPassword.vue";
import ProfileSidebar from "@/pages/profile/index.vue";
import OrderPage from "@/pages/profile/Order.vue";
import AddressPage from "@/pages/profile/Address.vue";
import BillingPage from "@/pages/profile/Billing.vue";
import NotFoundPage from "@/pages/404Page.vue";
import AboutUsPage from "@/pages/AboutUsPage.vue";
import NewsAndMediaPage from "@/pages/NewsandmediaPage.vue";
import JoinAsProviderPage from "@/pages/JoinAsProviderPage.vue";

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/#service',
    component: HomePage,
  },

  {
    path: '/#place-order',
    component: HomePage,
  },

  {
    path: '/#operation-mode',
    component: HomePage,
  },

  {
    path: '/#insights',
    component: HomePage,
  },

  {
    path: '/LoginPage',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: '/ForgotPasswordPage',
    name: 'ForgotPasswordPage',
    component: ForgotPasswordPage,
  },
  {
    path: '/ResetPasswordPage',
    name: 'ResetPasswordPage',
    component: ResetPasswordPage,
  },

  {
    path: '/AboutUsPage',
    name: 'AboutUsPage',
    component: AboutUsPage,
  },

  {
    path: '/NewsAndMediaPage',
    name: 'NewsAndMedia',
    component: NewsAndMediaPage,
  },

  {
    path: '/NewsAndMediaPage',
    name: 'NewsAndMediaPage',
    component: NewsAndMediaPage,
  },
  {
    path: '/JoinAsProviderPage',
    name: 'JoinAsProviderPage',
    component: JoinAsProviderPage,
  },
  {
    path: "/ProfileSidebar",
    name: "ProfileSidebar",
    component: ProfileSidebar,
    children: [
      {
        path: "",
        name: "OrderPage",
        component: OrderPage,
      },
      {
        path: "OrderPage",
        name: "OrderPage",
        component: OrderPage,
      },
      {
        path: "AddressPage",
        name: "AddressPage",
        component: AddressPage,
      },
      {
        path: "BillingPage",
        name: "BillingPage",
        component: BillingPage,
      },
    ],
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundPage',
    component: NotFoundPage,
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});


export default router;
