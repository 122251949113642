<template>
  <div  class="page-container">
    <!-- Title Section -->
    <div class="services-title">
      <h1 class="title-text">
        <span class="title-highlight">Collaboration</span> Mode
      </h1>
      <img src="/assets/images/greenTrunk.png" alt="Track Icon" class="title-icon"/>
    </div>
    <div class="services-container">
      <!-- Cards Section -->
      <div class="cards-container">
        <div class="card" v-for="(service, index) in services" :key="index">
          <div class="service-icon">
            <img :src="service.icon" :alt="`Icon for ${service.title}`" :style="{ width: '30px', height: '30px' }"/>
          </div>
          <p class="service-title">{{ service.title }}</p>
          <p class="service-description" v-html="service.description"></p>
        </div>
      </div>

    </div>
    <div class="contract-container">
      <div class="auth-page-container">
        <div class="tabs">
          <div class="tab contact-tab" :class="{ active: activeTab === 'contact' }" @click="activeTab = 'contact'">
            Contact Information
          </div>

          <div class="contact-description">
            Please leave your contact and your business <br> introduction, we will contact you with an offer shortly.
          </div>
        </div>


        <div class="input-container">
          <!-- Company Name -->
          <label for="company" class="label">Company Name</label>
          <input id="company" type="text" v-model="company" class="input">

          <!-- Contact Name -->
          <label for="contact" class="label">Contact Name</label>
          <input id="contact" type="text" v-model="contact" class="input">

          <!-- Phone Number -->
          <label for="phone" class="label">Phone Number</label>
          <input id="phone" type="text" v-model="phone" class="input">

          <!-- Email Address -->
          <label for="email" class="label">Email Address</label>
          <input id="email" type="email" v-model="email" class="input">

          <!-- SEND Button -->
          <button @click="handleSend" :disabled="loading" class="send-button">
            <span>SEND</span>
            <img src="/assets/images/ArrowRight.png" alt="Arrow Icon" class="arrow-icon">
          </button>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "JoinAsProviderPage",
  components: {
  },
  data() {
    return {
      services: [
        {
          icon: '/assets/images/Rss.png',
          title: '1. Free API Integration',
          description: `We provide Free API integration for your system and our software. We will make necessary upgrades for
                        your software when necessary. Connect to our interface, customers can check distance and price through our interface.`
        },
        {
          icon: '/assets/images/CurrencyDollar.png',
          title: '2. Advance Payment',
          description: 'Through our system, customer will make payment to you before sending out riders. You can secure the payment in advance.'
        },
        {
          icon: '/assets/images/Percent.png',
          title: '3. Commission Base',
          description: `We charge no fee in advance.
                        We only charge a commission for the new order we bring in through our software.
                        Together, we can reach more customers, and we can expand the service to more areas.
                        Your success is our wish!`
        }
      ]
    };
  }
};
</script>

<style scoped>
/* Base styles for the services container */
.services-container {
  display: flex;
  flex-direction: column;
  background: #F8FAFB;
  padding: 5%;
  gap: 20px;
}

/* Title section styling */
.services-title {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin: 80px 0 30px;
}

.title-text {
  font-family: 'Lato', sans-serif;
  font-size: clamp(1.5rem, 4vw, 2.5rem); /* Responsive font size */
  font-weight: 500;
  color: #333;
  text-align: center; /* Align for smaller screens */
}

.title-highlight {
  font-weight: 700;
  border-bottom: 4px solid #00AB4D;
}

.title-icon {
  width: clamp(100px, 15vw, 160px); /* Adjust size for responsiveness */
  height: clamp(100px, 15vw, 160px);
  transition: transform 0.3s ease;
}

.title-icon:hover {
  transform: rotate(15deg); /* Hover effect on title icon */
}

/* Cards container */
.cards-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 20px;
}

.card {
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

/* Service icon inside card*/
.service-icon {
  margin-bottom: 20px;
  width: clamp(20px, 4vw, 100px); /* Increased circle size */
  height: clamp(20px, 4vw, 100px); /* Increased circle size */
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Service title and description */
.service-title {
  font-family: Lato, sans-serif;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-weight: 600;
  color: #0F9D51;
  margin: 10px 0;
}

.service-description {
  word-wrap: break-word;
  font-family: Lato, sans-serif;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  line-height: 1.6;
  color: #555;
  text-align: justify;
}
.contract-container {
  display: flex;
  flex-direction: column;
  background: #00AB4DB2;
  width: 100%;
  padding: 5%;
  gap: 20px;
}
/* Input form container */
.input-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.auth-page-container {
  width: 30vw;
  /*height: 60vh;*/
  margin-top: 2vh;
  margin-bottom: 6vh;
  position: relative;
  left: 50%;
  transform: translate(-50%);  /*居中显示 */
  border: 1px solid #E4E7E9;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.12);
  padding: 24px;
  font-family: 'Public Sans', sans-serif;
}

.label {
  font-size: 14px;
  color: #191C1F;
}

.input {
  width: 100%;
  height: 44px;
  padding: 8px;
  border: 2px solid #E4E7E9;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Send button */
.send-button {
  width: 100%;
  height: 48px;
  background-color: #00AB4D;
  color: white;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-button:hover {
  background-color: #008A3D;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

/* Tabs */
.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab.contact-tab {
  padding: 12px 0;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tab.contact-tab.active {
  border-bottom-color: #FA8232;
  color: #000;
}

.contact-description {
  font-size: 14px;
  color: #5F6C72;
  margin: -10px 0 25px;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .services-container {
    padding: 30%;
  }

  .title-text {
    font-size: clamp(1.2rem, 4vw, 2rem);
    text-align: center;
  }

  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .card {
    width: 100%;
    padding: 15px;
  }

  .service-title {
    text-align: center;
  }
}

@media (max-width: 576px) {
  .title-text {
    font-size: clamp(1rem, 3vw, 1.5rem);
  }

  .card {
    width: 100%;
  }
}
</style>


