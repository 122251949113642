<template>
  <div class="page-container">
    <div class="container">
      <!-- Page Header -->
      <h2 class="header">Reset Password</h2>
      <p class="subheader">
        Enter your new password <br/>
        and confirm it to secure your account. <br/>
        Make it strong and memorable!
      </p>

      <!-- Password Input -->
      <div class="input-group">
        <label for="password" class="label">Password</label>
        <div class="input-wrapper">
          <input
              id="password"
              type="password"
              class="input"
          />
          <img
              src="/assets/images/Eye.png"
              alt="Toggle Password Visibility"
              class="toggle-icon"
          />
        </div>
      </div>

      <!-- Confirm Password Input -->
      <div class="input-group">
        <label for="confirm-password" class="label">Confirm Password</label>
        <div class="input-wrapper">
          <input
              id="confirm-password"
              type="password"
              class="input"
          />
          <img
              src="/assets/images/Eye.png"
              alt="Toggle Password Visibility"
              class="toggle-icon"
          />
        </div>
      </div>

      <!-- Reset Password Button -->
      <button class="reset-button">
        <span>RESET PASSWORD</span>
        <img
            src="/assets/images/ArrowRight.png"
            alt="Arrow Icon"
            class="arrow-icon"
        />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: "ResetPasswordPage",
  components: {
  },
  methods: {
    redirectToLoginPage() {
      this.$router.push({name: 'LoginPage'});
    },
  },
};
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@600&display=swap');

/* Page Styles */
.page-container {
  width: 30vw; /* 页面宽度 */
  height: 60vh; /* 页面高度 */
  position: relative; /* 设置为固定定位，确保相对整个窗口定位 */
  top: 400px; /* 顶部位置为窗口高度的50% */
  left: 50%; /* 左侧位置为窗口宽度的50% */
  transform: translate(-50%, -50%); /* 利用平移将元素真正居中 */
  border: 1px solid #E4E7E9; /* 边框颜色 */
  border-radius: 4px; /* 边框圆角 */
  background-color: white; /* 背景颜色 */
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.12); /* 阴影效果 */
  padding: 24px; /* 内部边距 */
  font-family: 'Public Sans', sans-serif; /* 字体 */
}

/* Container Styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Header Section */
.header {
  font-size: 20px;
  font-weight: 600;
  color: #191C1F;
  text-align: center;
  margin-bottom: 12px;
}

.subheader {
  font-size: 14px;
  font-weight: 400;
  color: #5F6C72;
  text-align: center;
  margin-bottom: 24px;
  line-height: 1.5;
}

/* Input Group */
.input-group {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers vertically */
}

.label {
  font-size: 14px;
  font-weight: 400;
  color: #191C1F;
  margin-bottom: 8px;
  display: block;
}

.input-wrapper {
  position: relative;
}

.input {
  width: 100%;
  height: 44px;
  padding: 12px 16px;
  border: 2px solid #E4E7E9;
  border-radius: 4px;
  box-sizing: border-box;
}

.toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* Reset Button */
.reset-button {
  width: 100%;
  height: 48px;
  background-color: #EE5858;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-button:hover {
  background-color: #d14c4c;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}
</style>
