<template>
  <div class="page-content-container">
    <div class="page-title-container">
      <h1 class="page-title">
        <span class="page-title-highlight">Price</span> Plan
      </h1>
    </div>
    <div class="product-container">
      <div class="product-option" v-for="(product, index) in products" :key="index">
        <h2 class="product-name">{{ product.name }}</h2>
        <p style="margin-top: 1.5rem;"><span class="product-price">{{ product.price }}</span> <br/>for single product</p>
        <p class="product-option-item"><strong>Product Weight: </strong>{{ product.weight }}</p>
        <p class="product-option-item"><strong>Region: </strong>{{ product.country }}</p>
        <p class="product-option-item"><strong>Duration: </strong>{{ product.duration }}</p>
        <p class="product-option-item"><strong>Support:</strong> {{ product.support }}</p>
        <p class="product-option-item"><strong>Tracking: </strong>{{ product.tracking }}</p>
        <button>ORDER NOW</button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ProductOptions',
  data() {
    return {
      products: [
        {
          name: "Basic",
          price: "ETB. 50",
          weight: "<=1KG",
          country: "Addis Ababa",
          duration: "within 1 day",
          support: "Yes",
          tracking: "YES",
        },
        {
          name: "Premium",
          price: "ETB. 150",
          weight: "5KG-10KG",
          country: "Addis Ababa",
          duration: "2 days",
          support: "Yes",
          tracking: "Yes",
        },
        {
          name: "Standard",
          price: "ETB. 300",
          weight: ">10KG",
          country: "Addis Ababa",
          duration: "2 days",
          support: "Yes",
          tracking: "Yes",
        },
      ],
    };
  },
}
</script>

<style scoped>
.product-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.product-name {
  background-color: #00AB4D;
  font-size: 1.2rem;
  width: 60%;
  padding: 15px 20px 15px 20px;
  text-align: center;
  color: white;
  border-radius: 10px;
}

.product-price {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 30px;
}

.product-option {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width: 25%;
  text-align: center;
  align-items: center;
  transition: all 0.3s ease;
}

.product-option:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
  transform: translateY(-5px); /* Slight upward movement on hover */
}

.product-option:focus {
  outline: 2px solid #0F9D51; /* Focus outline for accessibility */
}

.product-option-item {
  padding: 8px 20px 8px 20px;
  text-align: left;
  width: 90%;
  color: dimgrey;
}

button {
  background-color: #00AB4D;
  color: white;
  padding: 10px 90px;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  border-radius: 10px;
}

button:hover {
  background-color: #0c7a3f;
  opacity: 0.85; /* Hover opacity effect */
  transform: scale(1.05); /* Slight scale effect on hover */
}
</style>

