<template>
  <div class="auth-page-container">
    <div class="container">
      <!-- Header Section -->
      <h2 class="header">Forgot Password</h2>
      <p class="description">
        NOTE：Enter the email address or mobile phone number <br/>
        associated with your ET Port account.
      </p>

      <!-- Email Input Section -->
      <div class="input-group">
        <label for="email" class="label">Email Address</label>
        <input id="email" type="email" class="input"/>
      </div>

      <!-- Submit Button -->
      <button class="submit-button">
        <span>SEND CODE</span>
        <img src="/assets/images/ArrowRight.png" alt="Arrow Icon" class="arrow-icon"/>
      </button>

      <!-- Sign In / Sign Up Links -->
      <div class="account-actions">
        <div class="action">
          <span class="text">Already have an account?</span>
          <span class="link" @click="redirectToLoginPage">Sign In</span>
        </div>
        <div class="action">
          <span class="text">Don’t have an account?</span>
          <span class="link">Sign Up</span>
        </div>
      </div>

      <!-- Support Information -->
      <div class="support-info">
        <p>
          You may contact
          <span class="highlight">Customer Service</span>
          for help restoring access to your account.
        </p>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "ForgotPasswordPage",
  components: {},
  methods: {
    redirectToLoginPage() {
      this.$router.push({name: 'LoginPage'});
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@600&display=swap');

/* Page Styling */


/* Container Styling */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Header Section */
.header {
  font-size: 20px;
  font-weight: 600;
  color: #191C1F;
  text-align: center;
  margin-bottom: 12px;
}

.description {
  font-size: 12px;
  font-weight: 400;
  color: #5F6C72;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Input Group */
.input-group {
  width: 360px;
  margin-bottom: 32px;
}

.label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #191C1F;
  margin-bottom: 8px;
}

.input {
  width: 100%;
  height: 44px;
  padding: 12px 16px;
  border: 2px solid #E4E7E9;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Submit Button */
.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background-color: #00AB4D;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #008A3D;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

/* Account Actions */
.account-actions {
  margin-top: 24px;
  text-align: left;
  width: 100%;
}

.action {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.text {
  font-size: 14px;
  color: #5F6C72;
}

.link {
  font-size: 14px;
  font-weight: 500;
  color: #2DA5F3;
  cursor: pointer;
  margin-left: 4px;
}

.link:hover {
  text-decoration: underline;
}

/* Support Information */
.support-info {
  margin-top: 16px;
  font-size: 14px;
  color: #5F6C72;
  text-align: center;
  line-height: 1.5;
}

.highlight {
  color: #00AB4D;
  font-weight: 500;
}
</style>
