<template>
  <div class="page-content-container">
    <div class="page-title-container">
      <h1 class="page-title">
        <span class="page-title-highlight">Download</span> Our App
      </h1>
    </div>

    <div class="container">
      <!-- 左侧内容 -->
      <div class="left-content">
        <p class="description">
          With the ET Port Deliver APP, users can enjoy a hassle-free and efficient experience, ensuring fast and secure deliveries to meet daily needs with ease and convenience.
        </p>
        <div class="features">
          <div class="list-item">
            <img :src="require('@/assets/images/app-1.png')" alt="End to End">
            <span>End to End Facilitation</span>
          </div>
          <div class="list-item">
            <img :src="require('@/assets/images/app-2.png')" alt="Real Time Updates">
            <span>Real Time Updates & Tracking</span>
          </div>
          <div class="list-item">
            <img :src="require('@/assets/images/app-3.png')" alt="Safety">
            <span>Safety, Security, Confidentiality</span>
          </div>
          <div class="list-item">
            <img :src="require('@/assets/images/app-4.png')" alt="Save Time">
            <span>Save Time & Effort</span>
          </div>
        </div>
      </div>

      <!-- 右侧图像 -->
      <div class="right-content">
        <div class="phone-frame">
          <img :src="require('@/assets/images/appScreen2.png')" alt="Phone Screen 1" mode="aspectFit">
<!--          <div class="overlay blue">-->
<!--            <img :src="require('@/assets/images/logoObj.jpg')" alt="Logo">-->
<!--          </div>-->
        </div>
        <div class="phone-frame">
          <img :src="require('@/assets/images/appScreen1.png')" alt="Phone Screen 2">
<!--          <div class="overlay blue">-->
<!--            <img :src="require('@/assets/images/logoObj.jpg')" alt="Logo">-->
<!--          </div>-->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "AppDownloadSection",
};
</script>

<style scoped>
/* 布局样式 */


.page-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* 内容水平居中 */
  width: 100%;
  max-width: 70vw; /* 设置最大宽度 */
  gap: 20px;
  background-color: #F8FAFB;
}
.page-title-container{
  display: flex;
  justify-content: flex-start;
  margin-left: 0rem;
  width: 100%;
  background-color: #F8FAFB;
  padding: 3rem 4rem 1rem 4rem;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem 3rem 4rem;
  background-color:  #F8FAFB;
}
@media (min-width: 768px) {
  .container {
    flex-direction: row;
  }
}

/* 左侧内容样式 */
.left-content {
  max-width: 40rem;
}

.left-content h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #2d3748; /* 深灰色 */
}

.left-content p {
  margin-top: 1rem;
  color: #718096; /* 中灰色 */
  line-height: 1.6;
}
.description{
  font-size: 1.125rem;
}
.list-item {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.list-item img {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}

.list-item span {
  font-size: 1.125rem;
  font-weight: 500;
  color: #2d3748; /* 深灰色 */
}

/* 右侧内容样式 */
.right-content {
  display: flex;
  margin-top: 3rem;
  gap: 1rem;
}

@media (min-width: 768px) {
  .right-content {
    margin-top: 0;
  }
}

.phone-frame {
  position: relative;
  width: 13rem;
  height: 24rem;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.phone-frame img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay.blue {
  background-color: rgba(37, 99, 235, 0.6); /* 蓝色透明遮罩 */
}

.overlay.orange {
  background-color: rgba(234, 88, 12, 0.6); /* 橙色透明遮罩 */
}

.overlay img {
  width: 3rem;
}
</style>
