<template>
  <div class="services-container">
    <div class="services-title">
      <h1 class="title-text">
        <span class="title-highlight">Services</span> We Offer
      </h1>
      <img src="/assets/images/greenTrunk.png" alt="Track Icon" class="title-icon">
    </div>
    <div class="cards-container">
      <div class="card" v-for="(service, index) in services" :key="index">
<!--        <img :src="service.icon" :alt="service.title + ' Icon'" class="service-icon">-->
        <div class="service-icon" :style="{ backgroundImage: `url(${service.icon})` }"></div>
        <p class="service-title">{{ service.title }}</p>
        <p class="service-description">{{ service.description }}</p>
      </div>
    </div>
    <div class="button-container">
      <button class="request-service-button">Request Service</button>
      <button class="join-now-button">Join Now</button>
    </div>
  </div>
</template>

<script>
import GreenTrack from '../assets/images/greenTrunk-1.png';
import GreenPlane from '../assets/images/greenPlan.png';
import GreenShopBag from '../assets/images/greenBag.png';

export default {
  name: "AppService",
  data() {
    return {
      services: [
        {
          icon: GreenTrack,
          title: 'Find Truck Services',
          description: 'A pay as-you-go solution for: Truck reservation service, in-city truck service for same-day orders, long-term truck service booking, and more.'
        },
        {
          icon: GreenPlane,
          title: 'Global Freight',
          description: 'Search and compare the best shipping rates among dozens of trusted logistic partners freight.'
        },
        {
          icon: GreenShopBag,
          title: 'Package Delivery Solutions',
          description: 'Our package solutions for the last mile delivery allows you to find the best service provider. You can choose the lowest fare and fastest service for your trip, and be assured the safety of packages.'
        }
      ]
    };
  }
};
</script>
<style scoped>
/* Base styles for the services container */
.services-container {
  display: flex;
  flex-direction: column;
  background: #F8FAFB;
  padding: 5%;
  box-sizing: border-box;
  max-width: 100%;
  gap: 20px;
}

/* Title section styling */
.services-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 20px;
}

.title-text {
  font-family: 'Lato', sans-serif;
  font-size: clamp(1.5rem, 4vw, 2.5rem); /* Responsive font size */
  font-weight: 500;
  color: #333;
  text-align: center; /* Center align for smaller screens */
}

.title-highlight {
  font-weight: 700;
  border-bottom: 4px solid #00AB4D;
}

.title-icon {
  width: clamp(100px, 15vw, 160px); /* Adjust size for responsiveness */
  height: clamp(100px, 15vw, 160px);
  transition: transform 0.3s ease;
}

.title-icon:hover {
  transform: rotate(15deg); /* Hover effect on title icon */
}

/* Cards container */
.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  transition: all 0.3s ease;
}

.card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  max-width: 100%; /* Ensure cards fit within the container */
}

.card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
  transform: translateY(-5px); /* Slight upward movement on hover */
}

.card:focus {
  outline: 2px solid #0F9D51; /* Focus outline for accessibility */
}

/* Service icon inside card */
.service-icon {
  margin-bottom: 20px;
  width: clamp(40px, 8vw, 70px);
  height: clamp(40px, 8vw, 70px);
  background-size: 180% 180%;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.service-icon:hover {
  transform: scale(1.1); /* Scale effect on hover */
}

/* Service title inside card */
.service-title {
  font-family: Lato, sans-serif;
  font-size: clamp(1rem, 3vw, 1.5rem); /* Responsive title font size */
  font-weight: 600;
  line-height: 1.2;
  color: #0F9D51;
  margin: 10px 0;
  transition: color 0.3s ease;
  text-align: center; /* Align center for small screens */
}

.service-title:hover {
  color: #007d33; /* Slight color change on hover */
}

/* Service description inside card */
.service-description {
  font-family: Lato, sans-serif;
  font-size: clamp(0.9rem, 2.5vw, 1rem); /* Responsive description font size */
  line-height: 1.6;
  color: #555;
  transition: color 0.3s ease;
  text-align: justify; /* Ensure readability */
}

.service-description:hover {
  color: #333; /* Subtle color change on hover */
}

/* Button container styling */
.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 10px;
}

.request-service-button,
.join-now-button {
  padding: 15px 30px;
  border-radius: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease;
}

.request-service-button {
  border: 2px solid #0F9D51;
  background-color: white;
  color: #0F9D51;
}

.join-now-button {
  background-color: #0F9D51;
  color: white;
  border: none;
}

button:hover {
  background-color: #0c7a3f;
  opacity: 0.85; /* Hover opacity effect */
  transform: scale(1.05); /* Slight scale effect on hover */
}

button:focus {
  outline: 2px solid #0F9D51; /* Focus outline for accessibility */
}

/* Responsive styles */
@media (max-width: 768px) {
  .services-container {
    padding: 30%;
  }

  .title-text {
    text-align: center; /* Center-align title */
    font-size: clamp(1.2rem, 4vw, 2rem);
  }

  .cards-container {
    display: flex;
    flex-direction: column; /* Vertical alignment for cards */
    gap: 15px; /* Increased spacing between cards */
  }

  .card {
    width: 100%; /* Reduce card width on smaller screens */
    margin: 0 auto; /* Center cards horizontally */
    padding: 15px; /* Reduce padding for compact layout */
  }

  .button-container {
    flex-direction: horizontal; /* Buttons stacked vertically */
    gap: 20px; /* Increased spacing between buttons */
  }

  .request-service-button,
  .join-now-button {
    width: 40%; /* Reduce button width */
    margin: 0 auto; /* Center buttons */
  }
}

@media (max-width: 576px) {
  .title-text {
    font-size: clamp(1rem, 3vw, 1.5rem);
  }

  .request-service-button,
  .join-now-button {
    width: 40%; /* Full width buttons */
  }

  .card {
    width: 50%; /* Ensure full width on very small screens */
    margin: 0; /* Remove extra margin */
  }
}
</style>


