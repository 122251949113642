<template>
  <div class="page-container">
    <div class="about-us-container">
      <!-- About Us Section -->
      <div class="page-title">
        <h2><span class="page-title-highlight">About</span> Us</h2>
      </div>

      <!-- Our Service Section -->
      <div class="our-service-container">
        <h2 class="section-title">Our Service</h2>
        <div class="card-container">
          <div class="card-inner-container">
            <div v-for="(stat, index) in stats" :key="index" class="stat-card">
              <div class="icon-container">
                <img :src="stat.icon" :alt="stat.title + ' Icon'" class="stat-icon"/>
              </div>
              <div class="stat-details">
                <h3 class="stat-value">{{ stat.value }}</h3>
                <p class="stat-title" v-html="stat.title"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Image Section Below Our Service -->
      <div class="image-container">
        <img src="/assets/images/video 1 1.png" alt="Service Image" class="service-image"/>
      </div>


      <!-- About Us Section -->
      <div class="about-us-text">
        <h2>Meet Our Team</h2>
      </div>

      <!-- Image and Text Section -->
      <div class="image-text-container">
        <img src="/assets/images/Group 1688.png" alt="Tech Team Image" class="tech-image"/>
        <div class="text-container">
          <p class="tech-text"><span class="team-text-start">Our tech team</span>
            is the driving force behind the innovation and efficiency of our logistics service platform.
            Comprised of expert developers, system architects, and engineers, the team is dedicated to
            creating a seamless, reliable, and scalable platform that connects businesses and logistics providers.
            They specialize in cutting-edge technologies, including real-time tracking, AI-driven route
            optimization, and secure data integration, ensuring that our platform delivers unparalleled performance
            and user experience. By continuously innovating and adapting to market demands, our tech team
            empowers users with tools that make logistics faster, smarter, and more efficient.
          </p>
        </div>
      </div>

      <!-- Swapped Image and Text Section (Text First, Then Image) -->
      <div class="image-text-container">
        <div class="text-container">
          <p class="tech-text"><span class="team-text-start">Our customer Support</span>
            is the backbone of user satisfaction for our logistics service platform. Trained in understanding
            the unique needs of businesses and logistics providers, our team is equipped to handle inquiries,
            troubleshoot issues, and provide expert guidance to ensure smooth operations. With a commitment to
            fast response times and personalized assistance, we aim to build trust and reliability with our
            users. Whether it’s resolving technical challenges or helping users optimize their experience on the
            platform, our customer support team is always here to help, ensuring uninterrupted and efficient
            logistics operations.
          </p>
        </div>
        <img src="/assets/images/Group 1688.png" alt="Tech Team Image" class="tech-image"/>
      </div>


      <!-- New Container Section -->
      <div class="flex flex-col items-center w-[314px] h-[303.9px] gap-2 mt-[100px] mx-auto">
        <img :src="require('@/assets/images/logo.jpg')" alt="Unlock Potential" class="w-[152px] h-[114px] p-2"/>

        <p class="w-[269px] text-[22px] font-semibold text-[#363636] leading-[26.63px] mt-2">
          &nbsp; Unlock Your Full Potential
        </p>
        <p class="w-[269px] text-[20px] font-normal text-[#878787] leading-[24.2px] mt-1  whitespace-nowrap">
          Our Mission is Your Achievement
        </p>

        <button
            class="custom-button mt-2"
            @click="goToLoginPage">
          Get Started
        </button>
      </div>


      <!-- Footer Image -->
      <div class="w-full mt-[100px]">
        <img src="/assets/images/Group 1699.png" alt="Footer Image" class="w-full h-auto"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutUsPage",
  components: {},
  data() {
    return {
      stats: [
        {
          icon: '/assets/images/Frame.png',
          value: '1,500,000',
          title: `Number of Residents Cover <br> in Service Region`,
        },
        {
          icon: '/assets/images/Bicycle.png',
          value: '10+',
          title: `Fast-courier Company <br> Integrated`,
        },
        {
          icon: '/assets/images/Truck.png',
          value: '5+',
          title: `Truck Service Company <br> Integrated`,
        },
      ],
    };
  },
  methods: {
    goToLoginPage() {
      this.$router.push({path: '/LoginPage'});
    }
  }
};
</script>

<style scoped>
.about-us-container {
  margin-top: 110px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us-text {
  width: 82%;
  background-color: #fff;
  padding: 80px 20px 40px 20px;
}

.about-us-text h2 {
  color: #007f3d;
  font-size: 2rem;
  text-align: left;
}

.our-service-container {
  background-color: #00AB4DB2;
  color: #fff;
  text-align: center;
  padding: 40px 60px;
  border-radius: 10px;
  margin-top: 20px;
  width: 82%;
  position: relative;
}

.section-title {
  font-size: 2rem;
  text-align: left;
  margin-bottom: 30px;
  font-weight: bold;
}

.card-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #00AB4DB2;
  border-radius: 20px;
}

.card-inner-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.stat-card {
  width: 30%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* 垂直方向上下居中 */
  border-left: 2px solid #c4f1da;
}

.stat-card:first-child {
  border-left: none;
}

.stat-icon {
  width: 40px;
  height: 40px;
}

.stat-details {
  font-family: 'Lato', sans-serif;
}

.stat-value {
  font-size: 2.1rem;
  font-weight: bold;
}

.stat-title {
  font-size: 1rem;
  margin-top: 10px;
}

.image-container {
  width: 100%;
  text-align: center;
  margin-top: 60px;
}

.service-image {
  width: 973px;
  height: 596px;
  margin: 0 auto;
}

.image-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 80%;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
}

.tech-text {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  color: #000;
  word-wrap: break-word;
}

.team-text-start {
  font-size: 40px;
  font-weight: 400;
  color: #0F9D51;
}

.tech-image {
  width: 500px;
  height: auto;
}

.custom-button {
  width: 179px;
  height: 50px;
  background-color: #51AB3B;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  transition: background-color 0.3s;
}

.custom-button:hover {
  background-color: #388E3C;
}

@media (max-width: 600px) {
  .about-us-container {
    padding: 10px;
  }

  .about-us-text {
    padding: 10px;
  }

  .our-service-container {
    width: 100%;
    padding: 20px;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
  }

  .stat-card {
    background-color: #007f3d;
    width: 100%;
    padding: 15px;
    margin-top: 10px;
    border-radius: 10px;
  }

  .stat-icon {
    width: 30px;
    height: 30px;
  }

  .stat-value {
    font-size: 1.2rem;
  }

  .stat-title {
    font-size: 0.9rem;
    text-align: center;
  }

  .image-text-container {
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
  }

  .tech-text {
    font-size: 18px;
    width: 100%;
  }

  .tech-image {
    width: 100%;
  }
}
</style>
