<template>
  <footer class="footer">
    <div class="footer-container">
      <!-- Logo and Description Section -->
      <div class="footer-section">
        <img src="/assets/images/logoFooter.png" alt="Port Logo" class="footer-logo"/>
        <p class="footer-description">
          Port.et delivers top-notch tech <br/>support to facilitate your shipping <br/>and upgrade your experience.
          <br/>Let’s make your business move <br/>forward smoothly!
        </p>
      </div>

      <!-- Explore Section -->
      <div class="footer-section">
        <h3 class="footer-title">Explore</h3>
        <ul class="footer-links">
          <li>
            <router-link to="/AboutUsPage" class="footer-link">About Us</router-link>
          </li>
          <li>
            <a href="https://etbuy.et/" class="footer-link" target="_blank" rel="noopener noreferrer">
              ET Buy
            </a>
          </li>
          <li>
            <a href="https://www.cedoratech.com/" class="footer-link" target="_blank" rel="noopener noreferrer">
              Cedora Tech
            </a>
          </li>
          <li>
            <router-link to="/NewsAndMedia" class="footer-link">News and Media</router-link>
          </li>
        </ul>
      </div>


      <!-- Legal Section -->
      <div class="footer-section">
        <h3 class="footer-title">Legal</h3>
        <ul class="footer-links">
          <li><a href="/terms" class="footer-link">Terms and Privacy </a></li>
        </ul>
      </div>

      <!-- Social Media Section -->
      <div class="footer-section">
        <h3 class="footer-title">Social Media</h3>
        <div class="footer-social">
          <a href="https://www.facebook.com/share/183Gz8rbGL/?mibextid=wwXIfr" aria-label="Facebook" class="social-link"
             target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/facebook.jpg" alt="Facebook Icon" class="social-icon" loading="lazy"/>
          </a>
          <a href="#" aria-label="Twitter" class="social-link">
            <img src="/assets/images/twitter.jpg" alt="Twitter Icon" class="social-icon" loading="lazy"/> </a>
          <a href="#" aria-label="WhatsApp" class="social-link">
            <img src="/assets/images/whatsup.jpg" alt="WhatsApp Icon" class="social-icon" loading="lazy"/> </a>
          <a href="#" aria-label="Instagram" class="social-link">
            <img src="/assets/images/instagram.jpg" alt="Instagram Icon" class="social-icon" loading="lazy"/> </a>
        </div>
      </div>

      <div class="copyright-section">
        <p class="copyright-text">
          Copyright © <span>{{ currentYear }}</span> Cedora Technology (Hong Kong) Limited. All rights reserved.
        </p>
      </div>

    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
/* Footer overall styling */
.footer {
  background-color: #0f9d51;
  padding: 40px 20px;
  color: #ffffff;
  font-family: 'Lato', sans-serif;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

/* Footer container with flexible layout */
.footer-container {
  height: 240px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;
  gap: 20px;
  transition: all 0.3s ease;
}

/* Individual footer sections */
.footer-section {
  flex: 1 1 240px; /* Flexibly adjust sections */
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: left;
}

/* Footer logo styling */
.footer-logo {
  width: 80px;
  transition: transform 0.3s ease;
}

.footer-logo:hover {
  transform: rotate(10deg);
}

/* Text and description */
.footer-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
  transition: color 0.3s ease;
}

.footer-description:hover {
  color: #d0f5e1;
}

/* Section titles */
.footer-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #ffffff;
}

/* Footer link styling */
.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-link {
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-link:hover {
  text-decoration: underline;
  color: #d0f5e1;
  transform: translateX(5px); /* Smooth hover transition */
}

/* Social media container */
.footer-social {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  margin-top: 15px;
}

.social-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2); /* Scaling effect on hover */
  background-color: #d0f5e1; /* Smooth background color transition */
}

/* Copyright Section */
.copyright-section {
  position: absolute; /* Absolute positioning */
  left: 50%; /* Center horizontally */
  bottom: 10px; /* Push it slightly below */
  transform: translateX(-50%); /* Adjust for perfect centering */
  text-align: center;
  padding-top: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%; /* Ensure it spans the width of the footer */
}

.copyright-text {
  font-size: 14px;
  color: #d0f5e1;
  font-weight: 400;
  letter-spacing: 0.5px;
  transition: color 0.3s ease;
}

.copyright-text span {
  font-weight: 700;
  color: #ffffff;
  transition: color 0.3s ease;
}

.copyright-text:hover {
  color: #ffffff;
}

.copyright-text span:hover {
  color: #d0f5e1;
}

/* Responsive adjustments for medium screens */
@media (max-width: 1200px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-section {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-social {
    justify-content: center;
    margin-top: 20px;
  }

  .footer-logo {
    width: 100px;
  }

  .footer-title {
    font-size: 16px;
  }

  .footer-description {
    font-size: 13px;
  }

  .footer-link {
    font-size: 13px;
  }
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Switch to vertical flex */
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .footer-section {
    flex: 1 0 100%; /* Full width for sections */
    margin-bottom: 10px;
  }

  .footer-logo {
    width: 120px;
  }

  .footer-title {
    font-size: 18px;
  }

  .footer-description {
    font-size: 14px;
  }

  .footer-link {
    font-size: 14px;
  }

  .social-icon {
    width: 45px;
    height: 45px;
  }
}

/* Mobile-first design */
@media (max-width: 480px) {
  .footer-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    text-align: center;
    padding: 10px;
  }

  .footer-section {
    flex: 1 0 100%;
    margin-bottom: 15px;
  }

  .footer-logo {
    width: 100px;
  }

  .footer-title {
    font-size: 16px;
  }

  .footer-description {
    font-size: 12px;
  }

  .footer-link {
    font-size: 12px;
  }

  .footer-social {
    justify-content: center;
    gap: 12px;
  }

  .social-icon {
    width: 40px;
    height: 40px;
  }
}
</style>
